import styled from "styled-components";

const Container = styled("div")`
    width: 100vw;
    height: 75px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Logo = styled("img")`
    width: 100%
    height: 75px;
    object-fit: cover;
`

const Navbar = () => {
    return (
        <Container>
            <Logo src="/ManciniX.png" alt="metaMancini2"/>
        </Container>
    )
}

export default Navbar;